<template>
  <div class="box box--mapBlock">
    <div class="box__inner">
      <div class="mapBlock js-tabBox mapBlock--request js-customScroll">
        <vue-scroll :ops="ops">
          <div class="mapBlock__header">
            <div class="mapBlock__header-top">
              <div class="mapBlock__header-inner">
                <div class="mapBlock__title">Submit manual request</div>
              </div>
            </div>
            <div class="mapBlock__header-bottom">
              <router-link class="mapBlock__back" to="map-search">
                <span class="icon">
                  <SvgIcon name="left-arrow"/>
                </span>
                <span class="text">Back to map</span>
              </router-link>
            </div>
          </div>
          <div class="mapBlock__content">
            <div class="mapBlock__request">
              <form
                class="form form--flightRequest js-flightRequestForm js-ajaxForm"
                :class="[{'loading' : loading}, {'error' : priceError}]"
                data-href="/"
                data-action="flightRequest"
                id="flightRequestForm"
                @submit.prevent="submitHandler"
              >
                <div class="form__inner">
                  <div class="form__left">
                    <div class="form__section">
                      <div class="form__header">
                        <div class="form__title">Manual flight requests</div>
                        <!-- <div class="form__link js-autofill">Autofill from option on map</div> -->
                      </div>
                      <div class="form__content form__content--flight darkPicker">
                        <div class="form__labelGroup form__labelGroup--type">
                          <label class="form__label form__label--radio">
                            <input class="form__radio js-changeWay" type="radio" v-model="type" value="one-way"/>
                            <span class="form__radio-box">One-way</span>
                            <span class="form__radio-icon" :style="{width: '12px', height: '20px', marginRight: 0}">
                              <SvgIcon name="plane"/>
                            </span>
                          </label>
                          <label class="form__label form__label--radio">
                            <input class="form__radio js-changeWay" type="radio" v-model="type" value="round-trip"/>
                            <span class="form__radio-box">Round-trip</span>
                            <span class="form__radio-icon" :style="{width: '20px', height: '20px', marginRight: 0}">
                              <SvgIcon name="double-plane"/>
                            </span>
                          </label>
                        </div>
                        <div class="form__labelGroup form__labelGroup--booking">
                          <label class="form__label form__label--checkbox" :class="{checked: booking?.some(b => b.toLowerCase() === 'charter aircraft')}" >
                            <input class="form__checkbox" type="checkbox" v-model="booking" value="charter aircraft"/>
                            <span class="form__checkbox-box">Charter&nbsp;aircraft</span>
                          </label>
                          <label class="form__label form__label--checkbox" :class="{checked: booking?.some(b => b.toLowerCase() === 'charter seat')}" >
                            <input class="form__checkbox" type="checkbox" v-model="booking" value="charter seat"/>
                            <span class="form__checkbox-box">Charter&nbsp;space</span>
                          </label>
                          <!-- <label class="form__label form__label--checkbox">
                            <input class="form__checkbox" type="checkbox" v-model="booking" value="commercial seat"/>
                            <span class="form__checkbox-box">Commercial&nbsp;seats</span>
                          </label> -->
                        </div>
                        <div class="form__labelGroup form__labelGroup--route">
                          <label class="form__label form__label--text">
                            <div class="form__field-wrapper">
                              <input
                                class="form__field js-typeahead"
                                type="text"
                                v-bind:value="from"
                                data-type="from"
                                @input="getLocationsFrom($event.target.value)"
                                @focus="showTypeheadMenu('from')"
                                @blur="hideTypeheadMenu"
                                :disabled="loading"
                                placeholder="Take off"
                              />
                              <div
                                class="typeahead-menu typeahead-empty"
                                :class="{'typeahead-open' : fromOrTo === 'from'}"
                              >
                                <div class="typeahead-dataset typeahead-dataset-departureLocations">
                                  <div
                                    class="typeahead-suggestion typeahead-selectable"
                                    style="text-align: left;"
                                    data-type="select-from"
                                    v-for="(item, index) of locationsListFrom" :key="index"
                                    @click="selectLocation(item)"
                                  >{{item.name}}</div>
                                </div>
                              </div>
                            </div>
                            <span
                              v-if="$v.from.$dirty && !$v.from.required"
                              class="form__field-invalid form__field-invalid--right-side"
                            >
                            This field is required
                          </span>
                          </label>
                          <label class="form__label form__label--text">
                            <div class="form__field-wrapper">
                              <input
                                class="form__field js-typeahead"
                                type="text"
                                v-bind:value="to"
                                data-type="to"
                                @input="getLocationsTo($event.target.value)"
                                @focus="showTypeheadMenu('to')"
                                @blur="hideTypeheadMenu"
                                :disabled="loading"
                                placeholder="Land"
                              />
                              <div
                                class="typeahead-menu typeahead-empty"
                                :class="{'typeahead-open' : fromOrTo === 'to'}"
                              >
                                <div class="typeahead-dataset typeahead-dataset-departureLocations">
                                  <div
                                    class="typeahead-suggestion typeahead-selectable"
                                    style="text-align: left;"
                                    data-type="select-to"
                                    v-for="(item, index) of locationsListTo" :key="index"
                                    @click="selectLocation(item)"
                                  >{{item.name}}</div>
                                </div>
                              </div>
                            </div>
                            <span
                              v-if="$v.to.$dirty && !$v.to.required"
                              class="form__field-invalid form__field-invalid--right-side"
                            >
                            This field is required
                          </span>
                          </label>
                          <label class="form__label form__label--date">
                            <div class="form__field-wrapper" :class="{'form__field-wrapper-selected': dateRange.length > 0}">
                              <a-date-picker
                                v-if="type === 'one-way'"
                                placeholder="select date"
                                :showToday="false"
                                :allowClear="false"
                                v-model="date"
                                format="M/D/YYYY"
                                :disabled="loading"
                                :getCalendarContainer="getPickerContainer"
                              />
                              <a-range-picker
                                v-else
                                :placeholder="['select date', '']"
                                :separator="dateRange.length > 0 ? '-' : ''"
                                :allowClear="false"
                                v-model="dateRange"
                                format="M/D/YYYY"
                                :getCalendarContainer="getPickerContainer"
                              />
                            </div>
                            <span
                              v-if="$v.selectedDate.$dirty && !$v.selectedDate.required"
                              class="form__field-invalid form__field-invalid--right-side"
                            >
                              This field is required
                            </span>
                          </label>
                          <label class="form__label form__label--number js-counter">
                            <div class="form__field-wrapper">
                              <div class="form__icon">
                                <SvgIcon name="users"/>
                              </div>
                              <input
                                class="form__field js-counter-input"
                                type="number"
                                v-model="count"
                                min="1"
                                max="99"
                                @blur="input"
                                :disabled="loading"
                              />
                              <div class="form__arrow">
                                <div class="form__arrow-plus js-counter-plus" @click="plus">
                                  <SvgIcon name="up-arrow"/>
                                </div>
                                <div class="form__arrow-minus js-counter-minus" @click="minus">
                                  <SvgIcon name="down-arrow"/>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form__right">
                    <div class="form__section">
                      <div class="form__header">
                        <div class="form__title">Aircraft type</div>
<!--                        <div class="form__subtitle">Additional</div>-->
                      </div>
                      <div class="form__content form__content--aircraft">
                        <div class="form__labelGroup form__labelGroup--aircraft">
                          <label class="form__label form__label--checkbox" :class="{checked: aircraftType?.some(at => at.toLowerCase() === '256')}">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="256"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Turboprop <span>(Max 6 spaces)</span></span>
                          </label>
                          <label class="form__label form__label--checkbox" :class="{checked: aircraftType?.some(at => at.toLowerCase() === '2')}">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="2"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Very Light Jet <span>(Max 6 spaces)</span></span>
                          </label>
                          <label class="form__label form__label--checkbox" :class="{checked: aircraftType?.some(at => at.toLowerCase() === '4')}">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="4"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Light Jet <span>(Max 8 spaces)</span></span>
                          </label>
                          <label class="form__label form__label--checkbox" :class="{checked: aircraftType?.some(at => at.toLowerCase() === '8')}">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="8"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Midsize Jet <span>(Max 9 spaces)</span></span>
                          </label>
                          <label class="form__label form__label--checkbox" :class="{checked: aircraftType?.some(at => at.toLowerCase() === '16')}">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="16"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Super Midsize Jet <span>(Max 16 spaces)</span></span>
                          </label>
                          <label class="form__label form__label--checkbox" :class="{checked: aircraftType?.some(at => at.toLowerCase() === '32')}">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="32"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Heavy Jet <span>(Max 16 spaces)</span></span>
                          </label>
                          <label class="form__label form__label--checkbox" :class="{checked: aircraftType?.some(at => at.toLowerCase() === '512')}">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="512"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Ultra-Long-Range Jet <span>(Max 16 spaces)</span></span>
                          </label>
                          <label class="form__label form__label--checkbox" :class="{checked: aircraftType?.some(at => at.toLowerCase() === '64')}">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="64"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Executive Airliner <span>(Max 44 spaces)</span></span>
                          </label>
                          <label class="form__label form__label--checkbox" :class="{checked: aircraftType?.some(at => at.toLowerCase() === '128')}">
                            <input class="form__checkbox" type="checkbox" v-model="aircraftType" value="128"/>
                            <i class="form__checkbox-icon"></i>
                            <span class="form__checkbox-box">Helicopter <span>(Max 9 spaces)</span></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="form__section">
                      <div class="form__header">
                        <div class="form__title">max price</div>
                      </div>
                      <div class="form__content form__content--price">
                        <div class="form__labelGroup form__labelGroup--price">
                          <div class="form__textGroup js-price-box">
                            <label class="form__label form__label--text price" @click="getActiveRange">
                              <div class="form__field-wrapper">
                                <input
                                  class="form__field js-price-range-to"
                                  type="number"
                                  v-model="maxPrice"
                                  placeholder="e.g. 5000"
                                  autocomplete="off"
                                  :disabled="loading || priceRangeVisible"
                                  @blur="checkCorrectRange"
                                />
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form__button-wr">
                  <Spinner/>
                  <div class="form__label form__label--button">
                    <button class="form__button" :disabled="loading"><span class="text">Send request</span></button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
    <InitiateTransactionPopUp @continue="onContinue" />
    <CompleteTransactionPopUp @success="onSuccess" @error="onError" @receive-payment="onReceivePayment" />
    <InfoPopup @success="() => $router.push('/dashboard/manual-request')" />
  </div>
</template>

<script>
  import { bookingAPI } from '@/api/booking'
  import { searchAPI } from '@/api/search'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  import { Counter } from '@/js/plugins/counter'
  import { required } from 'vuelidate/lib/validators'
  import moment from 'moment'
  import './ManuallyRequest.styl'
  // --
  import InitiateTransactionPopUp from '@/components/common/modals/InitiateTransactionPopUp'
  import CompleteTransactionPopUp from '@/components/common/modals/CompleteTransactionPopUp'
  import InfoPopup from '@/components/common/modals/InfoPopup';
  import {loadStripe} from '@stripe/stripe-js';

  const counter = new Counter({
    min: 1,
    max: 99,
  })

  export default {
    name: 'ManuallyRequest',
    data: () => ({
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(244,244,244,0.2)',
        },
      },
      type: 'one-way',
      booking: ['charter aircraft'],
      arrivalId: '',
      departureId: '',
      direction: null,
      from: '',
      fromId: '',
      to: '',
      toId: '',
      fromOrTo: null,
      date: null,
      dateRange: [],
      selectedDate: null,
      count: null,
      loading: false,
      aircraftType: [],
      selectedBookingTypes: null,
      minPrice: '',
      maxPrice: '',
      priceRangeVisible: false,
      priceError: false,
      aircraftTypes: {
        2: 'VeryLightJet',
        4: 'LightJet',
        8: 'MidSizeJet',
        16: 'SuperMidSizeJet',
        32: 'HeavyJet',
        64: 'ExecutiveAirliner',
        128: 'Helicopter',
        256: 'Turboprop',
        512: 'Ultra-Long-Range',
      },
      requestId: null,
      price: 0,
    }),
    validations: {
      from: { required },
      to: { required },
      selectedDate: {required},
    },
    computed: {
      locationsListFrom () {
        return this.$store.getters.shortListLocationsFrom(4)
      },
      locationsListTo () {
        return this.$store.getters.shortListLocationsTo(4)
      },
    },
    async created () {
      const flightRequestId = new URLSearchParams(window.location.search).get('flightRequestId')
      let searchRequest = null

      if (flightRequestId) {
        searchRequest = await bookingAPI.getManualRequest(flightRequestId).then(d => d.data);
      }

      searchRequest = searchRequest ?? this.$store.state.search.searchRequest;

      this.maxPrice = searchRequest.maxPrice

      if (!searchRequest.returnDate) {
        this.type = 'one-way'
        this.date = searchRequest.departureDate
      } else {
        this.type = 'round-trip'
        this.dateRange[0] = searchRequest.departureDate
        this.dateRange[1] = searchRequest.returnDate
      }

      this.from = searchRequest.departureName ?? searchRequest.departure
      this.to = searchRequest.arrivalName ?? searchRequest.arrival
      this.count = searchRequest.pax ? searchRequest.pax : 2
      this.arrivalId = searchRequest.arrivalId
      this.toId = searchRequest.arrivalId
      this.departureId = searchRequest.departureId
      this.fromId = searchRequest.departureId
      this.selectedBookingTypes = searchRequest.bookingType ?? searchRequest.selectedBookingTypes

      this.aircraftType = this.getCheckboxValues(searchRequest.aircraftType).map(at => at.toString())

      console.log(this.aircraftType)
      if (this.selectedBookingTypes === 0) {
        this.booking = []
      } else if (this.selectedBookingTypes === 2) {
        this.booking = ['charter aircraft']
      } else if (this.selectedBookingTypes === 4) {
        this.booking = ['charter seat']
      } else if (this.selectedBookingTypes === 64) {
        this.booking = ['charter aircraft', 'charter seat']
      }
    },
    mounted () {
      this.$root.fields.changeRadio('.form__radio')
      this.$root.fields.changeChecked('.form__checkbox')
      setTimeout(async () => await this.showInfoAboutPayment(), 500)
    },
    methods: {
      onReceivePayment (status) {
        switch (status) {
          case 'succeeded':
          case 'processing':
            this.showInfoPopup('Payment Received', 'We will be in touch shortly with your requested availabilities.')
            break
          case 'requires_payment_method':
            this.showInfoPopup('Error', 'Your payment was not successful, please try again.')
            break
          default:
            this.showInfoPopup('Error', 'Something went wrong.')
            break
        }
        this.$modal.hide('complete');
      },
      async showInfoAboutPayment () {
        const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret')

        if (!clientSecret) {
          return
        }
        const stripe = await loadStripe(process.env.VUE_APP_PK_STRIPE)
        const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret)

        this.onReceivePayment(paymentIntent?.status)
      },
      showInfoPopup (title, text) {
        this.$modal.show('InfoPopup', {
          title,
          text,
        })
      },
      async getLocationsFrom (value) {
        this.from = value
        const that = this

        if (this.timeout != null) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(async function () {
          this.timeout = null

          const data = {
            query: that.from,
            type: 'from',
          }

          await that.$store.dispatch('searchLocations', data)
          that.fromOrTo = 'from'
          if (that.from === '' || !that.locationsListFrom.length) {
            // remove marker
            that.markerFrom = null
            that.fromOrTo = null
          }
        }, 500)
      },
      async getLocationsTo (value) {
        this.to = value
        const that = this

        if (this.timeout != null) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(async function () {
          this.timeout = null

          const data = {
            query: that.to,
            type: 'to',
          }

          await that.$store.dispatch('searchLocations', data)
          that.fromOrTo = 'to'
          if (that.to === '' || !that.locationsListTo.length) {
            // remove marker
            that.markerTo = null
            that.fromOrTo = null
          }
        }, 500)
      },
      showTypeheadMenu (type) {
        if (type === 'from' && this.locationsListFrom.length) {
          this.fromOrTo = 'from'
        } else if (type === 'to' && this.locationsListTo.length) {
          this.fromOrTo = 'to'
        } else {
          this.fromOrTo = null
        }
      },
      hideTypeheadMenu () {
        const that = this
        setTimeout(function () {
          that.fromOrTo = null
        }, 200)
      },
      selectLocation (item) {
        const that = this
        if (event.target.getAttribute('data-type') === 'select-from') {
          this.fromId = item.id
          this.from = item.name
        } else {
          this.toId = item.id
          this.to = item.name
        }
        setTimeout(function () {
          that.fromOrTo = null
        }, 200)
      },
      formatAircraftTypeString () {
        const typesArr = []

        if (this.aircraftType.length > 0) {
          this.aircraftType.forEach((item, index) => {
            typesArr.push(this.aircraftTypes[item])
          })
        } else {
          return 'Aircraft type not specified.'
        }

        return typesArr.join(', ')
      },
      onContinue () {
        this.$modal.hide('initiate')
        this.$modal.show(
          'complete',
          { transactionType: 'manual-request', requestId: this.requestId, price: this.price },
        )
      },
      onSuccess () {
        this.$modal.hide('complete')
        this.$modal.show('InfoPopup', {
          title: 'Payment Received',
          text: 'We will be in touch shortly with your requested availabilities.',
        })

        console.log('success')
        setTimeout(() => {
          this.$router.push('/dashboard/manual-request')
        }, 3000)
      },
      onError (error) {
        this.$modal.hide('complete')
        this.$modal.show('InfoPopup', {
          title: 'Error',
          text: error.message,
        });
        console.log('error')
      },
      // --
      setBookingType () {
        let bookingType

        if (this.booking.length === 1) {
          if (this.booking.includes('charter aircraft')) {
            bookingType = 2
          } else if (this.booking.includes('charter seat')) {
            bookingType = 4
          } else {
            bookingType = 8
          }
        } else if (this.booking.length === 2) {
          if (this.booking.includes('charter aircraft') && this.booking.includes('charter seat')) {
            bookingType = 64
          } else if (this.booking.includes('charter aircraft') && this.booking.includes('commercial seat')) {
            bookingType = 128
          } else if (this.booking.includes('charter seat') && this.booking.includes('commercial seat')) {
            bookingType = 254
          }
        } else if (this.booking.length === 3) {
          if (this.booking.includes('charter aircraft') &&
            this.booking.includes('charter seat') &&
            this.booking.includes('commercial seat')) {
            bookingType = 0
          }
        }

        return bookingType
      },
      moment,
      getPickerContainer (trigger) {
        return document.querySelector('.darkPicker')
      },
      getCheckboxValues (sum) {
        const checkboxValues = [512, 256, 128, 64, 32, 16, 8, 4, 2];
        const result = [];

        for (const value of checkboxValues) {
          if (sum >= value) {
            result.push(value);
            sum -= value;
          }
        }

        return result;
      },
      async submitHandler () {
        if (this.$v.$invalid) {
          this.$v.$touch()
          return false
        }

        this.loading = true

        const aircraftType = this.aircraftType.reduce((item, a) => (+item) + (+a), 0);

        const bookingType = this.setBookingType()
        const bookingTypeStr = bookingType === 64 ? 'both' : bookingType === 2 ? 'aircraft' : 'spaces';

        const payload = {
          aircraftType: aircraftType,
          direction: this.type === 'one-way' ? 2 : 1,
          bookingType: bookingType,
          departureId: this.fromId,
          arrivalId: this.toId,
          departureDate: this.type === 'one-way' ? this.date : this.dateRange[0],
          returnDate: this.type === 'round-trip' ? this.dateRange[1] : null,
          pax: Number(this.count),
          maxPrice: this.maxPrice ? Number(this.maxPrice) : null,
          notes: `Aircraft type(s):\n\t${this.formatAircraftTypeString()}\nBooking type(s):\n\t${bookingTypeStr}`,
        }

        try {
          const { data } = await searchAPI.createManualRequest(payload);

          this.requestId = data

          this.$router.push({
            query: { flightRequestId: this.requestId },
          });

          await bookingAPI.getManualPaymentInfo().then((res) => {
            this.price = res.data;
          });
            this.$modal.show('initiate', { transactionType: 'manual-request', price: this.price })
        } catch (e) {
          console.log(e);
          this.$emit('error')
        }

        this.loading = false
      },
      minus () {
        this.count = counter.callMinus(this.count)
      },
      plus () {
        this.count = counter.callPlus(this.count)
      },
      input () {
        this.count = counter.callInput(this.count)
      },
      getActiveRange () {
        this.priceRangeVisible = false
      },
      getActiveMax () {
        this.priceRangeVisible = true
      },
      checkCorrectRange () {
        const from = +(this.minPrice.slice(2).replace(/[\s,%]/g, ''))
        const to = +(this.maxPrice.slice(2).replace(/[\s,%]/g, ''))

        from >= to ? this.priceError = true : this.priceError = false
      },
    },
    watch: {
      date () {
        this.selectedDate = this.date
      },
      dateRange () {
        this.selectedDate = this.dateRange
      },
    },
    components: {
      SvgIcon,
      Spinner,
      InitiateTransactionPopUp,
      CompleteTransactionPopUp,
      InfoPopup,
    },
  }
</script>
